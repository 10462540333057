.tableSearch {
    display: flex;
    align-items: center;
  }
  .tableSearch input {
    margin-left: 8px;
  }
  .rdt_TableRow:nth-of-type(even) {
    background-color: #f3f4f7; 
  }
  .btn-primary
  {
    border-radius: 5px !important;
  }
  