@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body,* {font-family: "Open Sans", sans-serif; letter-spacing: 0;}
body, html {overflow-x: hidden;}
/* login page */
.loginPage{ max-width: 90%; margin: 0 auto;}
.loginPage header {padding: 25px; }
.loginPage .title {font-size: 2rem; font-weight: 600;letter-spacing: 0; line-height: 2.5rem;}
.loginPage .title span {font-weight: 700; font-size: 3rem;}
.loginPage header .form-control {width: 100px ; border:0 }
.language {text-align: left; outline: none; box-shadow: none;}
select {appearance: menulist!important;}
.loginPage .loginForm {min-height: 75vh;}
.loginPage .loginForm .card .logo {max-width: 180px;}
.loginPage .loginForm .form-control {padding:20px 15px; border-radius: 10px; margin-bottom: 30px;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;}
.btn-primary {background-color: #4461F2; border-color: #4461F2; border-radius: 10px;}
.loginPage .loginForm .btn {display: block;box-shadow: rgba(47, 118, 190, 0.2) 0px 8px 24px;}
.loginPage .loginForm .password {position: relative;}
.loginPage .loginForm .password span {position: absolute; right: 15px;  top: 19px; opacity: .8;}
.loginPage .loginForm a{text-decoration: none; color: #333;}
.loginPage .loginForm .leftImg {max-width: 100%;}
.loginPage footer {padding-top: 20px;}

/* dashboard */
/* header */
.navbar {width: 83%; right: 0;  background-color: #fff; position: fixed; top:0; border-bottom: solid 1px #eee; padding-top: 10px;}
.sidebar-toggler {background-color: transparent;border:0}
.navbar select {border:0; padding: 5px 10px; outline: 0;}
.dropdown-toggle {outline: none!important;border:0}
.dropdown-item {padding: 10px 15px;}
.dropdown-item:first-child {padding: 10px 15px 15px  ; margin-bottom: 10px; border-bottom: solid 1px #ddd;}
.main-title{font-size: 24px;}
.sidebar {position: fixed;width: 17%; left: 0; top:0; height: 100vh; padding: 0; background-color: #000;}
.sidebar ul li{display: block; margin-left: -30px;}
.sidebar .logo{text-align: center; padding: 15px;}
 
.sidebar ul li a {display: block; position: relative;  transition: all  ease .3s;   background-repeat: no-repeat!important; background-position: 12px 12px; margin: 10px 0; padding: 10px 20px 10px 50px; text-decoration: none; color: #fff!important;  border-radius: 30px 0 0 30px; }
.sidebar ul li a:hover, .sidebar ul li a.active, .sidebar ul li a:active {color: #4472D8!important; background-color: #fff; }
.sidebar ul li:first-child a {background-image: url('../images/sidebar-icons/bx_bxs-dashboard.png');  }
.sidebar ul li:first-child a:hover ,.sidebar ul li:first-child a.active {background-image: url('../images/sidebar-icons/Vector_1.png'); background-position: 12px 13px;  }
.sidebar ul li:nth-child(2) a {background-image: url('../images/sidebar-icons/users.png');  }
.sidebar ul li:nth-child(2) a:hover ,.sidebar ul li:nth-child(2) a.active {background-image: url('../images/sidebar-icons/users_1.png'); background-position: 12px 13px;  }
.sidebar ul li:nth-child(3) a {background-image: url('../images/sidebar-icons/driver.png');  }
.sidebar ul li:nth-child(3) a:hover ,.sidebar ul li:nth-child(3) a.active {background-image: url('../images/sidebar-icons/driver_1.png'); background-position: 12px 13px;  }
.sidebar ul li:nth-child(4) a {background-image: url('../images/sidebar-icons/dispacher.png');  }
.sidebar ul li:nth-child(4) a:hover ,.sidebar ul li:nth-child(3) a.active {background-image: url('../images/sidebar-icons/people_1.png'); background-position: 12px 13px;}
.sidebar ul li a span{float: right;} 
.sidebar ul li ul {margin-left: 50px; margin-top: 0px; height: 0; opacity: 0; transition: all  ease .3s;}
.sidebar ul li.open ul {height: 100%; opacity: 1;}
.sidebar ul li ul li a {font-size: 14px; background-image: none!important; padding:10px 0 0; background-color: transparent!important; }
.main-content {margin-left: 17%; padding:10px 25px 50px; margin-top: 85px; }
.main-content .page-title {font-size: 24px;}
.tbl_btns .btns {background-color: transparent; border:0;width: 29px}
.rdt_TableHeadRow{border-radius: 10px;overflow: hidden; border:0!important}
.rdt_TableHeadRow .rdt_TableCol{background-color: #F7F8FA; font-weight: 600; font-size: 14px;}
.hSQKHD{border:0!important; }
.rdt_TableCell{font-size: 13px;}
.tableSearch {position: relative; }
.tableSearch span{position: absolute; left: 10px; top: 5px; font-size: 20px;}
.tableSearch input {width: 100%; padding: 10px 40px;}
.badge-warning{
    display: inline-block;
    padding: 5px 10px;
    line-height: 1;
    text-transform: capitalize;
    border-radius: 50px;
    text-align: center;
    font-size: 11px;
}
.form-control
{
    height: 45px;
    margin-bottom:10px ;
}
.custom-file-input-container {
    position: relative;
    width: 100%;
  }
  
  .custom-file-input-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    color: #999;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .custom-file-input-label img {
    color: #666;
    /* margin-bottom: 10px; */
  }
  
  .custom-file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  .center-tabs {
    display: flex;
    justify-content: center;
    
  }
  .nav-item button
  {
    color: #000;
  }
  .nav-tabs .nav-link {
    border:1px solid;
    border-bottom: 0;
    border-top-left-radius: var(--bs-nav-tabs-border-radius);
    border-top-right-radius: var(--bs-nav-tabs-border-radius);
    margin-left: 10px;
}
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active
  {
    background: #333333;
    color: #fff;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:hover
  {
    background: #333333;
    color: #fff;
  }
  .user-top {
    background: #032F67 url(../images/usertop-bg.png) no-repeat top left;
    background-size: cover;
    border-radius: 7px 7px 0px 0px;
    height: 102px;
    margin-bottom: 50px;
    padding: 0 40px;
}
.user-top .user-profile .profile-img {
  background: #D9D9D9;
  border-radius: 100px;
  width: 124px;
  height: 124px;
  padding: 0;
}
.user-top .user-profile .profile-img img {
  border: 2px solid #fff;
  padding: 0;
  height: 124px;
}
.user-profile .profile-img img {
  width: 100%;
  border-radius: 100%;
}
.user-top>.row {
  top: 30px;
  position: relative;
}
.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.user-title .card-title {
  color: #fff;
  margin-bottom: 45px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.03em;
}
.col-group {
  background: #FFFFFF;
  border: 2px solid #F3F3F3;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 7px;
  padding: 17px 24px;
  margin-top: 23px;
}
.col-group label {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #393939;
  display: block;
}
.col-group span {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #212529;
}
footer {
  position: fixed !important;
  
  bottom: 0 !important;
  width: 100%;
}
.skeleton-table {
  width: 100%;
  border-collapse: collapse;
}

.skeleton-header,
.skeleton-cell {
  padding: 10px;
}

.skeleton-line {
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
